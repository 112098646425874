@use '../variables' as app;;

#template-generator-view {
    button.back-button {
        margin: 0;
        padding: 0;
        display: flex;
        cursor: pointer;
        border: none;
    }

    .filter-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: app.$grid * 2;

        .search-toggle {
            display: flex;
            .search-input {
                position: relative;
                width: 250px;
                height: 100%;
                input {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    padding-left: 1em;
                    font-size: 1em;
                    border: 1px solid grey;
                    border-radius: app.$border-radius;
                }
                .input-icon {
                    font-size: 34px;
                    position: absolute;
                    top: 0.28em;
                    right: 0.2em;
                }
            }
            .toggle-buttons {
                width: 400px;
                margin-left: app.$grid * 2;
            }
        }
        button#download-template {
        }
    }

    .category-selector {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        button {
            font-size: 0.9em;
            border: none;
            padding: app.$grid;
            margin: app.$grid/2;

            &:first-child {
                // more space after the "All" button
                margin-right: app.$grid;
            }
        }
    }
}

#amenity-table {
    margin-top: app.$grid * 3;
    border-collapse: collapse;
    width: 100%;

    thead {
        color: white;
        background-color: app.$logo-blue;

        th {
            padding: 0.5em 0;
            text-align: left;
            &#checkbox-name,
            &#checkbox-properties {
                width: 30%;
                > div {
                    display: flex;
                    .checkbox {
                        margin: 0 app.$grid;
                        //background-color: white;
                    }
                }
            }
        }
    }
    tbody {
        tr {
            border-bottom: 1px solid grey;

            td {
                height: 5em;
                .selector,
                .amenity-properties {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .checkbox {
                        margin: app.$grid / 2 app.$grid;
                    }
                    label {
                        cursor: pointer;
                    }
                }

                .amenity-properties {
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    div {
                        display: flex;
                        align-items: center;

                        label {
                            margin-right: app.$grid * 2;
                        }
                    }
                }
            }
        }
    }
}
