@use '../variables' as app;;

.checkbox {
    $size: app.$grid * 2;
    border: 2px solid grey;
    border-radius: app.$border-radius;
    cursor: pointer;
    width: $size;
    height: $size;
    position: relative;

    .material-icons {
        position: absolute;
        font-size: $size + 2;
        top: -1px;
        left: -1px;
        color: white;
        display: none;
    }
    &.checked {
        border-color: app.$link-color;
        background-color: app.$link-color;

        .material-icons {
            display: inline;
        }
    }
}
