@use '../variables' as app;;

table.status-table {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
        color: app.$text-color;
        padding: 0.25em 1.5em;
        border: 1px solid #ddd;
    }

    th {
        font-size: 13px;
        font-weight: normal;
        background-color: #f5f5f5;

        &.summary {
            width: 20%;
        }

        &.counts {
            border-bottom: none;
        }
    }

    td {
        font-size: 12px;

        &.file-name {
            width: 8em;
            div {
                display: flex;
                align-items: center;
                vertical-align: middle;
                justify-content: space-between;
            }
        }

        &.number {
            text-align: center;
            padding: 0;
            &.error {
                font-weight: bold;
                color: app.$error-color;
            }
        }

        &.status {
            text-align: center;
        }

        &.summary {
            padding: 0;
        }
    }

    tr {
        &.actions th {
            // refresh link and spinner
            position: relative;
            float: left;
            text-align: left;
            border: none;
            background: none;
            padding: 0 0 1em;
            // set fixed height so switch to spinner doesn't "bounce" the layout
            height: 24px;

            .filters {
                white-space: nowrap;
                float: left;
                select {
                    margin: 0 0.5em;
                }
            }

            .refresh {
                padding-left: 0.5em;
                white-space: nowrap;
                a {
                    padding: 0.3em;
                }
            }

            .spinner {
                // spinner is taller than line height
                top: -8px;
                position: absolute;
            }
        }
        &.counts {
            border-bottom: none;
            th {
                // row counts
                border: none;
                padding: 0.2em 0;
                width: 5.5em;
            }
        }
        &.no-matches td {
            font-size: 14px;
            padding: 1em;
            text-align: center;
        }
    }
}

table.file-summary {
    // override styles inherited from table.status-table
    border-collapse: collapse;
    width: 100%;
    td,
    th {
        padding: 0.2em 0.5em;
        border: none;
        font-size: smaller;
    }
    th {
        text-align: right;
        padding-left: 1em;
        font-weight: bold;
    }

    tr:first-of-type {
        th,
        td {
            padding-top: 0.75em;
        }
    }
    tr:last-of-type {
        th,
        td {
            padding-bottom: 0.75em;
        }
    }

    td {
        &.time,
        &.percent-done {
            text-align: right;
        }
    }
}

.link-margin {
    margin-left: 20px;
}
