:root {
    /* https://css-tricks.com/snippets/css/system-font-stack/ */
    /* https://www.smashingmagazine.com/2015/11/using-system-ui-fonts-practical-guide/ */
    --san-serif-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
}

.fetcher-container {
    width: 100%;
    .fetcher-input {
        width: 100%;
        height: 100px;
        margin-bottom: 10px;
        resize: none;
        font-family: var(--san-serif-font);
    }
}
