@use '../variables' as app;;

.role-error {
    // match basic error styles in /finances/view.scss for upload failure
    display: flex;
    align-items: center;
    width: 100%;
    color: app.$error-color;
    border: 1px solid app.$error-color;
    padding: 2em;

    .material-icons {
        margin-right: 1em;
    }
}
