@use '../variables' as app;;

table.fix-table {
    border-collapse: collapse;

    th,
    td {
        color: app.$text-color;

        padding: 1em 1.5em;
        border: 1px solid #ccc;
    }

    th {
        font-size: 13px;
        font-weight: normal;
        background-color: #f5f5f5;
    }

    td {
        font-size: 12px;

        &.index {
            // row number
            width: 2em;
            text-align: right;
        }

        &.edit {
            // fields with errors are editable padding: 3px;
            text-align: center;

            input:not([type='checkbox']):not([type='image']) {
                border: 1px solid app.$error-color;
                border-radius: app.$border-radius;
                font-size: 1em;
                padding: 0.5em;
                width: 7em;
            }

            input[type='checkbox'] {
                cursor: pointer;
            }
        }
    }

    tr {
        &.invalid td.edit input {
            border-color: app.$error-color;
        }

        &.valid td.edit input {
            border-color: app.$success-color;
        }
    }

    .pillbox-invalid {
        background-color: red;
        color: white;
        border-radius: 5px;
        margin-top: 4px;
        height: 17px;
        font-size: 10px;
    }
}
