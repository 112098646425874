@use '../variables' as app;;

#modal {
    label {
        cursor: inherit;
    }
    .confirmation.window {
        min-width: 800px;

        h2 {
            margin: 0 0 1em 0;
        }

        .success,
        .error {
            display: flex;
            align-items: center;
            font-weight: bold;
            margin-top: 1em;
            .material-icons {
                padding-right: 6px;
            }
        }

        .success {
            color: app.$success-color;
        }

        .error {
            color: app.$error-color;
        }

        .fixes {
            display: flex;
            flex-direction: column;

            .fixes-hint {
                margin-left: 2em;
            }

            // a 'fix' section is shown for each FixSpec identified
            // while parsing an uploaded CSV, each section gives
            // the appropriate options to "fix" the invalid CSV info
            section.fix {
                margin: 1em 0 0 2em;

                // fix-title is a container for label-count-icon for a 'fix'
                div.fix-title {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    max-width: 80%;
                    align-items: center;

                    label {
                        color: app.$error-color;
                        font-weight: normal;
                        &.fix-count {
                            margin-left: 0.3em;
                            font-weight: bold;
                        }
                    }
                    .material-icons {
                        margin-left: 0.5em;
                        text-align: end;
                        color: app.$error-color;
                        font-size: 2em;
                        line-height: 0.5em;
                    }
                }

                table.fix-table {
                    margin-top: 0.3em;
                    margin-bottom: 3em;
                }
            }
        }
    }
}
