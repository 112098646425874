@use '../variables' as app;;

$padding: 3em;

#modal {
    .overlay {
        position: fixed;
        background-color: rgba(255, 255, 255, 0.7);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: $padding;
        z-index: 100;

        .window {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            min-width: 40%;
            max-width: 80%;
            width: min-content;

            min-height: 40%;
            max-height: 80%;
            height: min-content;

            padding: $padding;
            background-color: white;
            border: 1px solid grey;
            overflow: auto;

            > .close.material-icons {
                position: absolute;
                top: 0.5em;
                right: 0.5em;
                color: app.$link-color;
                cursor: pointer;
            }

            nav.actions {
                display: flex;
                justify-content: flex-end;
                margin-top: $padding;

                button {
                    margin-left: 1em;
                }
            }
        }
    }
}
