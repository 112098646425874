// https://vacasa.invisionapp.com/share/UJTY53B8SCF#/screens/383947187_import_Reservations_-_Empty
@use 'variables' as app;

:root {
    /* https://css-tricks.com/snippets/css/system-font-stack/ */
    /* https://www.smashingmagazine.com/2015/11/using-system-ui-fonts-practical-guide/ */
    --san-serif-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
}

$logo-height: 60px;
$logo-width: 200px;

@each $name in (8, 16, 24, 32) {
    .pl-#{$name},
    .px-#{$name} {
        padding-left: #{$name}px !important;
    }
    .pr-#{$name},
    .px-#{$name} {
        padding-right: #{$name}px !important;
    }
    .pt-#{$name},
    .py-#{$name} {
        padding-top: #{$name}px !important;
    }
    .pb-#{$name},
    .pb-#{$name} {
        padding-bottom: #{$name}px !important;
    }
}

body {
    font-family: var(--san-serif-font);
    padding: 0;
    margin: 0;
}

a,
.link {
    color: app.$link-color;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: app.$highlight-color;
        text-decoration: underline;
    }
}

h1,
h2,
h3,
h3,
label,
p {
    color: app.$text-color;
}

input:focus {
    outline: none !important;
}

#root {
    padding: 0;
    position: relative;
    margin: 0 auto;

    header {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        height: $logo-height;

        &::after {
            position: absolute;
            font-size: 20px;
            font-weight: bold;
            left: calc(50% + 10px);
            top: $logo-height + 22px;
            letter-spacing: 4px;
            transform: translate(-50%, -50%);
            text-transform: uppercase;
        }

        &.env-dev::after {
            color: app.$success-color;
            content: 'Development';
        }

        &.env-stage::after {
            color: app.$error-color;
            content: 'Staging';
        }

        div {
            max-width: 1600px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img.logo {
                width: $logo-width;
                margin: 0.5em 0 0.8em 2em;
                cursor: pointer;
            }

            h1 {
                margin: 0;
                padding: 0;
            }

            a.home:hover {
                text-decoration: none;
            }

            nav {
                position: relative;
                display: flex;
                align-items: center;
                height: $logo-height;

                p {
                    margin: 0;
                    padding: 0 0 0 2em;
                    border-left: 2px solid #ddd;
                    cursor: pointer;
                }
                .material-icons {
                    margin-right: 0.5em;
                }

                ul {
                    position: absolute;
                    top: $logo-height / 3 + 3;
                    width: 100%;
                    color: app.$link-color;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-top: none;
                    padding: 0.75em 1em;
                    list-style: none;

                    li {
                        white-space: nowrap;
                        display: flex;
                        align-items: center;
                        padding: 0.5em 0;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    main {
        width: app.$body-width;
        margin: 2.5em auto;

        h1 {
            font-size: 3em;
        }

        > p {
            width: 75%;
            line-height: 1.5em;
        }
    }

    &.blurred {
        filter: blur(3px);
    }

    nav.view-menu {
        margin: 0 auto;
        width: 690px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-content: stretch;

        .father-bulk {
            position: relative;
            border: 1px solid;
            border-color: app.$primary-color;
            padding: 1em;
            margin: 1em;
            flex-grow: 1;
            text-align: center;
            width: 150px;

            i.material-icons {
                position: absolute;
                left: 0.5em;
            }
        }

        .child-bulk {
            position: absolute;
            z-index: 999;
            background-color: #fff;
            width: 100%;
            top: 50px;
            left: -1px;
            border-right: 1px solid #000;
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;

            ul {
                list-style: none;

                li {
                    text-align: left;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    padding: 0 20px 0 10px;
                }
            }
        }

        .child-bulk-i {
            position: relative !important;
            float: right;
            height: 0;
            margin: -2px 5px 0 0;
        }
    }
}

.center-loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-column {
    flex-direction: column;
    align-items: center;
}

.fileModal {
    min-height: 25%!important;
}

.fileModal button {
    margin-top: 50px;
}

.center {
    text-align: center;
}