@use '../variables' as app;;

.toggle-buttons {
    display: flex;

    button {
        border-radius: 0;
        border-right: none;
        padding-left: 0.75em;
        padding-right: 0.75em;
        flex-basis: 0;
        flex-grow: 1;

        &:last-child {
            border-right: 1px solid app.$link-color;
            border-top-right-radius: app.$border-radius;
            border-bottom-right-radius: app.$border-radius;
        }
        &:first-child {
            border-top-left-radius: app.$border-radius;
            border-bottom-left-radius: app.$border-radius;
        }
    }
}
