.role-checkbox-flex {
  display: flex;
  flex-flow: row wrap;
}

.role-checkbox-div {
  width: 150px;
}

.action-buttons {
  .delete-button,
  .update-button {
    margin-top: 8px;
  }
}