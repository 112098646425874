@forward '../node_modules/@vacasa/base-style-lib/bootstrap/scss/color' as color-*;
@use '../node_modules/@vacasa/base-style-lib/bootstrap/scss/color';
//@import 'node_modules/@vacasa/base-style-lib/bootstrap/scss/variables';

$logo-blue: #003349;
//$dark-color: color.value('dark');
$dark-color: color.value('midnight-90');
$text-color: rgb(45, 45, 45);
$primary-color: color.value('primary');
$yellow: color.value('owner-yellow');
$yellow-light: color.value('guest-yellow');
$yellow-dark: color.value('vacasa-yorange');
$highlight-color: color.value('jewel');
$success-color: color.value('moss');
$error-color: color.value('sunset');
$info-color: color.value('surf');
$warning-color: color.value('clay');
$link-color: #0078ab; //$info-color;

$body-width: 1024px;
$border-radius: 4px;
$grid: 8px;

@mixin triangle($size, $color, $offset: 2px) {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;

    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    top: -($size - $offset);
    margin-left: calc(50% - #{$size});
}
