@use '../variables' as app;;

table.fix-table {
    // included so rules below have enough specificity to override defaults
    div.number-table {
        position: relative;

        > input[type='text'] {
            border: 1px solid #ccc;
            width: 3.5em;
            text-align: left;
            padding-right: 2.5em; // space for the plus button
            cursor: pointer;
        }

        .material-icons {
            position: absolute;
            top: 3px;
            right: 5px;
            cursor: pointer;
            color: #999;
        }

        table.sub-table {
            $arrow-size: 10px;
            // pop-up table
            position: absolute;
            z-index: 100;
            margin-top: 4px;
            margin-left: -50%;
            //border-collapse: collapse;
            border: 1px solid #ccc;
            background-color: #fff;

            tbody tr:last-child td {
                padding-bottom: 1em;
            }

            th,
            td {
                color: app.$text-color;
                padding: 0.75em;
                border: none;

                &:first-of-type {
                    padding-left: 1em;
                }
                &:last-of-type {
                    padding-right: 1em;
                }
            }

            th {
                font-weight: normal;
                background-color: #f5f5f5;
            }

            td {
                background-color: #fff;
                padding: 3px;

                input {
                    border: 1px solid #ccc;
                    border-radius: app.$border-radius;
                    padding: 0.5em;
                    width: 4em;
                    text-align: left;

                    &:invalid {
                        border-color: app.$error-color;
                    }

                    &[type='number'] {
                        width: 5em;
                    }
                }
                &.number input {
                    text-align: right;
                }
            }

            // arrow background (border)
            &:before {
                @include app.triangle($arrow-size, #ccc);
            }

            // arrow foreground
            &:after {
                @include app.triangle($arrow-size - 2, #fff);
            }
        }
    }

    input[type='number'] {
        text-align: right;
        width: 4em;
    }

    div.toggle {
        $radius: 10px;
        display: flex;
        margin: 0;
        button {
            width: 3em;
            padding: 5px;
            text-align: center;
            border-radius: 0;
            cursor: pointer;

            &.Yes {
                border-top-left-radius: $radius;
                border-bottom-left-radius: $radius;
            }
            &.NA {
                border-left: none;
                border-right: none;
            }
            &.No {
                border-top-right-radius: $radius;
                border-bottom-right-radius: $radius;
            }

            &.selected {
                color: #fff;
                &.Yes {
                    background-color: app.$success-color;
                }
                &.NA {
                    background-color: app.$info-color;
                }
                &.No {
                    background-color: app.$error-color;
                }
            }
            &:hover,
            &.selected {
                &.Yes {
                    border-color: app.$success-color;
                }
                &.NA {
                    border-color: app.$info-color;
                }
                &.No {
                    border-color: app.$error-color;
                }
            }
        }

        &.error {
            // toggle value is required but neither option was selected
            margin-top: 0 !important; // override .confirmation.window .error
            button {
                border-color: app.$error-color;
            }
        }
    }
}
