@use '../variables' as app;;

button {
    font-size: 1em;
    padding: 0.7em 2em;
    border: 1px solid grey;
    border-radius: app.$border-radius;
    cursor: pointer;
    outline: none;

    &.primary {
        border: none;
        background-color: app.$primary-color;
    }

    &.secondary {
        border-color: app.$link-color;
        color: app.$link-color;
    }

    &.small {
        margin-left: 2em;
        padding: 0.25em;
    }

    &.active {
        background: app.$link-color;
        border-color: app.$link-color;
        color: white;
    }
}
