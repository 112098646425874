@use '../variables' as app;;

$text: app.color-value('midnight-40');
$border: app.color-value('midnight-30');
$active: app.color-value('midnight-90');

.uploader {
    label {
        display: block;
        font-weight: 600;
        margin: 2em 0 1em 0;

        &.zero-fees {
            cursor: pointer;
        }
    }

    .file-drop {
        padding: 2em 0;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed $border;
        cursor: pointer;

        i.material-icons {
            color: $text;
            margin-right: 0.5em;
        }

        p {
            color: rgb(100, 100, 100);
            margin: 0;

            .link {
                color: app.$link-color !important;

                &:hover {
                    color: app.$highlight-color;
                    text-decoration: underline;
                }
            }
        }

        &:hover {
            i,
            p {
                color: $active;
            }
        }
    }

    .confirm-file {
        .pending-file {
            display: flex;
            align-items: center;

            .cancel-button {
                margin-left: 1em;
                cursor: pointer;
                &:hover {
                    color: app.$highlight-color;
                }
            }
        }
        .upload-button {
            display: flex;
            align-items: center;
            margin-top: 1em;

            button {
                margin: 0 1em 0 0;
            }
        }
    }

    .failure {
        // match basic error styles in /components/requires-role.scss
        position: relative;
        width: 100%;
        display: flex;
        border: 1px solid app.$error-color;
        padding: 2em 3em 2em 2em;

        h4 {
            margin-top: 0;
            padding-top: 0;
        }

        .material-icons {
            &.close {
                position: absolute;
                top: 1em;
                right: 1em;
                cursor: pointer;
            }
            &.error {
                color: app.$error-color;
                margin-right: 1em;
            }
        }
    }
}
